export const sendToPublisherWebsite = (payementType, url) => {
  let publisherWebsite = document.referrer;
  window.parent.postMessage(
    { paymentType: payementType, url },
    publisherWebsite,
  );
};

export const PaymentType = {
  wechat: "wechat",
  alipay: "alipay",
};
