export const PaymentType = {
  wechat: "wechat",
  alipay: "alipay",
};

export const PaymentTheme = {
  stripe: "stripe",
};

export const ClientMode = {
  web: "web",
};

export const PaymentMethod = {
  wechat: "wechat_pay",
  alipay: "alipay",
};
